import { useEffect } from "react"

import { Box, HStack } from "@chakra-ui/react"

declare global {
  interface Window {
    hbspt: any
    lintrk: (command: string, options: { conversion_id: number }) => void
  }
}

export const HubspotContactFormWidget = ({ frameless }: { frameless?: boolean }) => {
  const elementId = frameless ? `hubspot-form-frameless` : `hubspot-form`

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.charset = "utf-8"
    script.async = true

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "40960101",
          formId: "b829feba-1aba-4ae2-89d0-786b52dba337",
          target: `#${elementId}`,
          onFormSubmitted: function () {
            if (window.lintrk) {
              window.lintrk("track", { conversion_id: 20928025 })
            }
          },
        })
      }
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [elementId])

  return (
    <>
      {frameless ? (
        <div
          id={elementId}
          style={{
            marginLeft: "-20px",
            marginRight: "-20px",
          }}
        ></div>
      ) : (
        <HStack align="center" justify="center">
          <Box borderRadius="8px" background="var(--website-color-highlight)" fontWeight="700" fontFamily="var(--landing-page-font)" py="48px" px="60px" maxWidth="460px" mb="64px">
            <div
              id={elementId}
              style={{
                marginLeft: "-20px",
                marginRight: "-20px",
              }}
            ></div>
          </Box>
        </HStack>
      )}
    </>
  )
}
